import React from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from '@mui/material';

import { getRegionCodes } from '@luxuryescapes/lib-regions';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { createReferralEarnOption, createReferralEarnOptionPayload } from '~/services/PromoService';

import {
  EarnOptionBrand,
  EarnOptionRegion,
  EarnOptionType,
  EarnOptionTypeCode,
  ReferralEarnStep,
  earnTypeIsCredit,
  earnTypeIsPromoCode,
} from './utils';

interface Props {
  closeModal: () => void;
  fetchData: () => void;
}

const DEFAULT_EXPIRY_MONTHS = 12;

function ReferralEarnOptionForm({ closeModal, fetchData }: Props) {
  const [region, setRegion] = React.useState<EarnOptionRegion | null>(null);
  const [name, setName] = React.useState<string>('');
  const [summary, setSummary] = React.useState<string>('');
  const [referralEarnTypeCode, setReferralEarnTypeCode] = React.useState<EarnOptionTypeCode | null>(null);
  const [referralEarnType, setReferralEarnType] = React.useState<EarnOptionType | null>(null);
  const [referralEarnStep, setReferralEarnStep] = React.useState<ReferralEarnStep | null>(null);
  const { tenant } = useCurrentTenant();
  const { enqueueSnackbar } = useSnackbar();

  const handleReferralEarnTypeChange = (value: EarnOptionTypeCode) => {
    setReferralEarnTypeCode(value);
    if (value === 'credit') {
      setReferralEarnType({
        earn_type_code: value,
        kind: 'PaymentService',
        earn_model: {
          value: 50,
          kind: 'FlatModel',
        },
        creditExpiresMonths: DEFAULT_EXPIRY_MONTHS,
      });
    } else if (value === 'promo_code') {
      setReferralEarnType({
        earn_type_code: value,
        code_name: '',
        promo_expires_months: DEFAULT_EXPIRY_MONTHS,
      });
    } else {
      setReferralEarnType(null);
    }
  };

  const handleCreate = async () => {
    const payload: createReferralEarnOptionPayload = {
      region: region,
      name: name,
      summary: summary,
      earn_type: referralEarnType,
      earn_priority: 0,
      earn_step: referralEarnStep,
      brand: tenant.brand as EarnOptionBrand,
    };
    const response = await createReferralEarnOption(payload);
    if (response.status === 201) {
      reset();
      closeModal();
      enqueueSnackbar('Referral Earn Option created successfully', {
        variant: 'success',
      });
      fetchData();
    } else {
      enqueueSnackbar('Error creating referral earn option', {
        variant: 'error',
      });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <Dialog fullWidth maxWidth="md" open onClose={closeModal} component="form" onSubmit={handleSubmit(handleCreate)}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="modal-title">
        Create a Referral Earn Option
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Autocomplete
                  id="region"
                  options={getRegionCodes()}
                  value={region}
                  onChange={(_, newValue) => {
                    setRegion(newValue as EarnOptionRegion);
                  }}
                  renderInput={(params) => <TextField {...params} label="Region" />}
                />
                <Autocomplete
                  id="referral_earn_type"
                  options={['credit', 'promo_code']}
                  value={referralEarnTypeCode}
                  onChange={(_, newValue) => {
                    handleReferralEarnTypeChange(newValue as EarnOptionTypeCode);
                  }}
                  renderInput={(params) => <TextField {...params} label="Referral Earn Type" />}
                />
                <Autocomplete
                  id="earn_step"
                  options={['signup', 'booking', 'post_travel']}
                  value={referralEarnStep}
                  onChange={(_, newValue) => {
                    setReferralEarnStep(newValue as ReferralEarnStep);
                  }}
                  renderInput={(params) => <TextField {...params} label="Referral Earn Step" />}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <TextField
                  id="summary"
                  label="Summary"
                  variant="outlined"
                  value={summary}
                  error={!!errors.summary}
                  helperText={errors.summary?.message}
                  autoComplete="off"
                  onChange={(e) => setSummary(e.target.value)}
                />
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  value={name}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <TextField
                  id="display_name"
                  label="Display Name"
                  variant="outlined"
                  {...register('display_name', {
                    required: 'Display Name is required',
                  })}
                  error={!!errors.header_text}
                  helperText={errors.header_text?.message}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
          </Grid>
          {referralEarnTypeCode && earnTypeIsCredit(referralEarnType) && (
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="sub-title">
                  Credit Details
                </DialogTitle>
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  id="value"
                  label="Credit Value"
                  variant="outlined"
                  value={referralEarnType?.earn_model.value}
                  autoComplete="off"
                  onChange={(e) =>
                    setReferralEarnType({
                      ...referralEarnType,
                      earn_model: { ...referralEarnType?.earn_model, value: Number(e.target.value) },
                    })
                  }
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  id="creditExpiresMonths"
                  label="Credit Expires Months"
                  variant="outlined"
                  value={referralEarnType?.creditExpiresMonths}
                  autoComplete="off"
                  onChange={(e) =>
                    setReferralEarnType({
                      ...referralEarnType,
                      creditExpiresMonths: Number(e.target.value),
                    })
                  }
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {referralEarnTypeCode && earnTypeIsPromoCode(referralEarnType) && (
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="sub-title">
                  Credit Details
                </DialogTitle>
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  id="code_name"
                  label="Promo Code Name"
                  variant="outlined"
                  value={referralEarnType?.code_name}
                  autoComplete="off"
                  onChange={(e) =>
                    setReferralEarnType({
                      ...referralEarnType,
                      code_name: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  id="promo_expires_months"
                  label="Promo Expires Months"
                  variant="outlined"
                  value={referralEarnType?.promo_expires_months}
                  autoComplete="off"
                  onChange={(e) =>
                    setReferralEarnType({
                      ...referralEarnType,
                      promo_expires_months: Number(e.target.value),
                    })
                  }
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {referralEarnTypeCode && (
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button variant="contained" type="submit">
            Create
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ReferralEarnOptionForm;
