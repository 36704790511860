import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { operations } from '@luxuryescapes/contract-svc-promo';
import { getRegionCodes } from '@luxuryescapes/lib-regions';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { expirePromoDisplayConfig, getPromoDisplayConfigs } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';

import PromoDisplayConfigForm from './PromoDisplayConfigForm';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 0.5,
};

const getColumns = ({
  handleExpirePromoConfig,
  handleEditPromoDisplayConfig,
}: {
  handleExpirePromoConfig: ({ id }: { id: string }) => Promise<void>;
  handleEditPromoDisplayConfig: (config: any) => void;
}): Array<GridColDef> => [
  {
    field: 'promo_display_config_id',
    headerName: 'ID',
    ...sharedOpts,
  },
  {
    field: 'code_name',
    headerName: 'Promo Code Name',
    ...sharedOpts,
    renderCell: (params) => {
      return (
        <Link to={`/promos/code/${encodeURIComponent(params.row.code_name)}`} target="_blank" rel="noreferrer">
          {params.row.code_name}
        </Link>
      );
    },
  },
  {
    field: 'region',
    headerName: 'Region',
    ...sharedOpts,
    flex: 0.3,
  },
  {
    field: 'url_path',
    headerName: 'URL Path',
    ...sharedOpts,
  },
  {
    field: 'utm_params',
    headerName: 'UTM Params',
    renderCell: (params) => {
      return (
        <Stack>
          <Typography>
            <b>Source:</b> {params.row.source}
          </Typography>
          <Typography>
            <b>Medium:</b> {params.row.medium}
          </Typography>
          <Typography>
            <b>Campaign:</b> {params.row.campaign}
          </Typography>
        </Stack>
      );
    },
    ...sharedOpts,
    flex: 0.7,
  },
  {
    field: 'text',
    headerName: 'Text',
    renderCell: (params) => {
      return (
        <Stack>
          <Typography>
            <b>Header Text</b> {params.row.header_text}
          </Typography>
          <Typography>
            <b>Body Text</b> {params.row.body_text}
          </Typography>
        </Stack>
      );
    },
    ...sharedOpts,
    flex: 1.6,
  },
  {
    field: 'modes',
    headerName: 'Modes',
    renderCell: (params) => {
      return (
        <Stack>
          <Typography>
            <b>Auth State</b> {params.row.auth_state}
          </Typography>
          <Typography>
            <b>Display Mode</b> {params.row.display_mode}
          </Typography>
          <Typography>
            <b>Delay duration</b> {params.row.delay_duration}
          </Typography>
          <Typography>
            <b>Targeted promo enable</b> {params.row.is_targeted_promo ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            <b>Targeted promo audience length</b> {params.row.targeted_promo_audience_length}
          </Typography>
        </Stack>
      );
    },
    ...sharedOpts,
    flex: 1,
  },
  {
    field: 'metadata',
    headerName: 'Metadata',
    renderCell: (params) => {
      return (
        <Stack spacing={1}>
          <Typography>
            <b>Created At</b> {dateFormatter(params.row.created_at)}
          </Typography>
          <Typography>
            <b>Updated At</b> {dateFormatter(params.row.updated_at)}
          </Typography>
          <Typography>
            <b>Expired At</b> {dateFormatter(params.row.expired_at)}
          </Typography>
          <Typography>
            <b>Created By </b>
            <Link to={`/users/${params.row.created_by}`}>{params.row.created_by}</Link>
          </Typography>
          <Typography>
            <b>Updated By </b>
            <Link to={`/users/${params.row.updated_by}`}>{params.row.updated_by}</Link>
          </Typography>
          <Typography>
            <b>Expired By </b>
            <Link to={`/users/${params.row.expired_by}`}>{params.row.expired_by}</Link>
          </Typography>
        </Stack>
      );
    },
    ...sharedOpts,
    flex: 1.25,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      const isExpired = params.row.expired_at && new Date(params.row.expired_at) < new Date();
      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isExpired}
            onClick={() => {
              handleExpirePromoConfig({ id: params.row.promo_display_config_id });
            }}
          >
            Expire
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isExpired}
            onClick={() => {
              handleEditPromoDisplayConfig(params.row);
            }}
          >
            Edit
          </Button>
        </Stack>
      );
    },
    ...sharedOpts,
    flex: 1,
  },
];

type PromoDisplayConfig = 'active' | 'expired' | undefined;
type PromoRegionType = operations['getPromoDisplayConfigs']['parameters']['query']['region'];
type PromoDisplayConfigType =
  operations['getPromoDisplayConfigs']['responses']['200']['content']['application/json']['result']['promoDisplayConfigs'];

function PromoDisplayConfigContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<PromoDisplayConfigType>([] as PromoDisplayConfigType);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState<number>(query.get('page_num') ? Number(query.get('page_num')) : 0);
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : 10);
  const [urlPath, setUrlPath] = useState<string>(query.get('url_path') || undefined);
  const [promoCodeName, setPromoCodeName] = useState<string>(query.get('code_name') || undefined);
  const [region, setRegion] = useState<PromoRegionType>(query.get('region') as PromoRegionType);
  const [source, setSource] = useState<string>(query.get('source') || 'ALL');
  const [medium, setMedium] = useState<string>(query.get('medium') || 'ALL');
  const [campaign, setCampaign] = useState<string>(query.get('campaign') || 'ALL');
  const [status, setStatus] = useState<PromoDisplayConfig | 'all'>(
    (query.get('status') as PromoDisplayConfig | 'all') || undefined,
  );
  const [totalCount, setTotalCount] = useState(0);
  const { tenant } = useCurrentTenant();
  const history = useHistory();
  const [editableConfig, setEditableConfig] =
    useState<operations['updatePromoDisplayConfig']['parameters']['body']['payload']>(null);

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      page_num: pageNum.toString(),
      page_size: pageSize.toString(),
      status: status,
      promo_code_name: promoCodeName,
      url_path: urlPath,
      source,
      medium,
      campaign,
      region,
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [history, pageNum, pageSize, status, promoCodeName, urlPath, source, medium, campaign, region]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        result: { promoDisplayConfigs, total },
      } = await getPromoDisplayConfigs({
        page: pageNum,
        pageSize: pageSize,
        status: status === 'all' ? undefined : status,
        code_name: promoCodeName,
        url_path: urlPath,
        source,
        medium,
        campaign,
        brand: tenant.brand === 'newwhitelabel' || tenant.brand === 'yidu' ? 'luxuryescapes' : tenant.brand,
        region: region ?? 'AU',
      });
      setData(promoDisplayConfigs ?? []);
      setIsLoading(false);
      setTotalCount(Number(total));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Promo Display Config fetch failed', { variant: 'error' });
      return;
    }
  }, [
    pageNum,
    pageSize,
    status,
    promoCodeName,
    urlPath,
    source,
    medium,
    campaign,
    tenant.brand,
    region,
    setQueryParams,
    enqueueSnackbar,
  ]);

  const dataRows = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    const timer = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timer);
  }, [pageNum, pageSize, status, promoCodeName, urlPath, source, medium, campaign, region, fetchData]);

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page);
      setPageSize(pageSize);
      query.set('page_num', page.toString());
      query.set('page_size', pageSize.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
      fetchData();
    },
    [fetchData, query],
  );

  // START - New Markup Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setEditableConfig(null);
    setOpen(true);
  };

  const handleEditPromoDisplayConfig = (config) => {
    setEditableConfig(config);
    setOpen(true);
  };

  // END - New Markup Modal

  const handleExpirePromoConfig = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await expirePromoDisplayConfig(id);
        enqueueSnackbar('Expired the promo display config successfully', { variant: 'success' });
        fetchData();
      } catch (error) {
        enqueueSnackbar(`Failed to expired the promo display config: ${error}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, fetchData],
  );

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Promo Display Config</title>
      </Helmet>
      <PageSubheader title={`Search Promo Display Config [${data?.length} of ${totalCount}]`} />
      <Grid container spacing={2} justifyContent="flex-end" alignItems="end">
        <Grid xs={8} container spacing={1}>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <TextField
                id="code_name"
                label="Promo Code Name"
                variant="outlined"
                value={promoCodeName}
                onChange={(e) => setPromoCodeName(e.target.value)}
              />
              <TextField
                id="url_path"
                label="URL Path"
                variant="outlined"
                value={urlPath}
                onChange={(e) => setUrlPath(e.target.value)}
              />
              <Autocomplete
                id="region"
                options={getRegionCodes()}
                onChange={(_, value) => setRegion((value as PromoRegionType) ?? 'AU')}
                renderInput={(params) => <TextField {...params} label="Region" />}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Tooltip
                title="Use special value 'ALL' to search for all sources, mediums, and campaigns"
                placement="bottom"
              >
                <TextField
                  id="source"
                  label="Source"
                  variant="outlined"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                title="Use special value 'ALL' to search for all sources, mediums, and campaigns"
                placement="bottom"
              >
                <TextField
                  id="medium"
                  label="Medium"
                  variant="outlined"
                  value={medium}
                  onChange={(e) => setMedium(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                title="Use special value 'ALL' to search for all sources, mediums, and campaigns"
                placement="bottom"
              >
                <TextField
                  id="campaign"
                  label="Campaign"
                  variant="outlined"
                  value={campaign}
                  onChange={(e) => setCampaign(e.target.value)}
                />
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <InputLabel>Status</InputLabel>
          <Select value={status} label="Status" onChange={handleStatusChange}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            New Promo Display Config
          </Button>
        </Grid>
      </Grid>
      {open && (
        <PromoDisplayConfigForm open={open} setOpen={setOpen} fetchData={fetchData} editableConfig={editableConfig} />
      )}
      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns({ handleExpirePromoConfig, handleEditPromoDisplayConfig })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
          rowHeight={200}
        />
      </Box>
    </>
  );
}

export default PromoDisplayConfigContainer;
