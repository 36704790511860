import qs from 'qs';

import { definitions, operations } from '@luxuryescapes/contract-svc-promo';

import { filterOutEmptyFields } from '~/utils/objectUtils';

import { dateNow, formatDateDateTimeWidget } from './TimeService';
import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

export type PromoMeta = operations['getPromoMeta']['responses']['200']['content']['application/json']['result'];

export type ReferralConfig = definitions['GlobalReferralConfig'];

export type PromoToggleKey = PromoMeta['promo_toggles']['0']['key'];
export type PromoToggleConfig = PromoMeta['promo_toggles'];

export type PromoRequestSource =
  operations['calculateDiscount']['parameters']['body']['Discount Request']['requestSource'];

type PromoRequestWarn = {
  message: string;
  severity: 'error' | 'warning' | 'info';
};

export type PromoConditionRaw = definitions['PromoCodeConditionPresented'];

export type PromoCondition = Omit<definitions['PromoCodeConditionPresented'], 'id_promo_code_condition'> &
  Partial<Pick<definitions['PromoCodeCondition'], 'id_promo_code_condition'>> & {
    isNew: boolean;
    id?: string;
    config?: definitions['PromoMeta']['promo_condition_types']['0'];
  };

export type InternalPromoWithDiscounts = definitions['InternalPromoWithDiscounts'];

/**
 * We include 'unknown' to account for <PromoLogs/> 'ALL' view which does not have the promo_type readily available (yet)
 */
export type PromoTypesAndUnknown =
  | operations['calculateDiscount']['responses']['200']['content']['application/json']['result']['promo']['promo_type']
  | 'unknown';

export type PromoRequest = definitions['Discount Request'];

export type PromoProductTypes = definitions['PromoMeta']['promo_product_types'];

export type PromoRegionGroupings = definitions['PromoMeta']['promo_region_groupings'];

export type GetPromoUserFilters = operations['getPromoUsers']['parameters']['query'];

export type PromoUser =
  operations['getPromoUsers']['responses']['200']['content']['application/json']['result']['promoUsers']['0'];

type RequestItem =
  operations['calculateDiscount']['responses']['200']['content']['application/json']['result']['promo']['items']['0'] & {
    travellers: {
      firstName: string;
      lastName: string;
    };
  };

export type DiscountItemInfo = {
  rId: string;
  item: {
    poffer: {
      itemId?: string | null;
      offerId: string;
      productBK: string;
      categoryBK: string;
      isStaffDiscountEnabled: boolean;
    };
    reqItem: RequestItem;
  };
  warning: string;
  discount: number;
  discountAmount: number;
  discountType?: 'percentage' | 'fixed_amount';
  matchingDiscount?: {
    region: string;
    products?: Array<string>;
    min_spend?: number | null;
    created_at: string;
    updated_at: string;
    modified_by: string;
    max_discount?: number | null;
    id_promo_code: string;
    discount_value: number;
    id_promo_code_discount: string;
    subscription_item_discount_type: string;
    subscription_item_discount_value: number;
  };
  luxPlusDiscountHigher: boolean;
  doesNotQualifyWithMemberPricing: boolean;
  discountHasCombinedWithLuxPlusPricing: boolean;
};

// TODO: update with contract type after merge
function getOrderCompareWarnings(
  order: definitions['Discount Request']['order'],
  orderV2: definitions['Discount Request']['preCheckoutOrder'],
): Array<PromoRequestWarn> {
  //definitions['PromoCodeRequest']
  const warnings: Array<PromoRequestWarn> = [];
  if (!orderV2 || !order) {
    warnings.push({ message: 'No body found', severity: 'error' });
    return warnings;
  }

  return warnings;
}

type getFilterTitleProps = {
  prefix: string;
  codeName?: string;
  userId?: string;
  isDev?: boolean;
};

export const getFilterTitle = ({ prefix, codeName, userId, isDev }: getFilterTitleProps): string => {
  let title = '';
  if (codeName && codeName?.toLowerCase() !== 'all') {
    title += `of '${codeName}'`;
  }
  if (userId) {
    title += `by ${userId}`;
  }

  if (isDev) {
    title += ` (including deleted)`;
  }

  return `${prefix} ${title.length > 0 ? '' + title : ''}`;
};

export const sortPromoItemByCategoryAndId = (a, b) => {
  if (a.categoryBK === b.categoryBK) {
    const idA = a.offerId || a.itemId || '';
    const idB = b.offerId || b.itemId || '';

    return idA.localeCompare(idB);
  }

  return a.categoryBK.localeCompare(b.categoryBK);
};

export const displayItemType = (i): string => {
  return `${i.categoryBK}
    ${i.parentType !== i.categoryBK ? `/${i.parentType}` : ''}              
    ${i.itemType !== i.categoryBK ? `/${i.itemType}` : ''}`;
};

export const hasAdvanceRestrictions = (promoCode): boolean => {
  if (!promoCode) {
    return false;
  }
  return (
    promoCode?.email_addresses?.length > 0 ||
    promoCode?.email_domains?.length > 0 ||
    promoCode?.offer_whitelist?.length > 0 ||
    promoCode?.offer_blacklist?.length > 0 ||
    promoCode?.bin_numbers?.length > 0 ||
    promoCode?.allowed_payment_methods?.length > 0 ||
    promoCode?.promo_toggles?.length > 0 ||
    promoCode?.device_types?.length > 0 ||
    promoCode?.allowed_airline_carriers?.length > 0 ||
    promoCode?.is_corporate
  );
};

export const getPromoRequestWarnings = (promoRequest: definitions['PromoCodeRequest']): Array<PromoRequestWarn> => {
  const warnings: Array<PromoRequestWarn> = [];
  const reqBody = promoRequest.bodyJSON as unknown as definitions['Discount Request'];

  warnings.concat(getOrderCompareWarnings(reqBody.order, reqBody.preCheckoutOrder));

  if (!reqBody) {
    return warnings;
  } else {
    if (promoRequest.statusCode === 500) {
      warnings.push({ message: 'Internal Server Error', severity: 'error' });
    }
  }
  return warnings;
};

type PromoItemDataWarning = {
  message: string;
  code: 'LUX_PLUS_PRICE_TOO_HIGH' | 'CATEGORY_BK_MISSING' | 'SUB_CATEGORY_BK_MISSING' | 'NO_TRAVELLERS';
  severity: 'error' | 'warning';
};

export const getPromoItemDataWarnings = (discountItem: DiscountItemInfo) => {
  const errors: Array<PromoItemDataWarning> = [];

  if (
    discountItem.item.reqItem.luxPlusPrice > 0 &&
    discountItem.item.reqItem.discountableTotal > discountItem.item.reqItem.luxPlusPrice
  ) {
    errors.push({
      message: `LuxPlusPrice calculated ${discountItem.item.reqItem.luxPlusPrice} is higher than the discountableTotal (${discountItem.item.reqItem.discountableTotal})`,
      code: 'LUX_PLUS_PRICE_TOO_HIGH',
      severity: 'error',
    });
  }

  if (!discountItem.item.reqItem.categoryBK) {
    errors.push({
      message: `CategoryBK is not present on request item`,
      code: 'CATEGORY_BK_MISSING',
      severity: 'error',
    });
  }

  return errors;
};

interface ProductTypeOption {
  product_bk: string;
  category_bk: string;
  value: definitions['Item Product BK Options'];
  label: string;

  /**
   * @description If true, this option will be hidden from the UI (but still available if already set on the promo code)
   */
  preferToHide?: boolean;
}

/**
 *
 * @param promoProductTypes All promo_product_types from the getPromoMeta endpoint
 * @param filterFields specific product_bk to filter to
 * @param filterType default filtering type (either for min_spend_products or products)
 * @returns Array<ProductTypeOption>
 */
export const getProductTypeOptions = (
  promoProductTypes: PromoProductTypes,
  filterFields = [],
): Array<ProductTypeOption> => {
  return promoProductTypes.flatMap((ppt) => {
    const prods = ppt.products
      .filter((p) => !p.dontShowInAdmin)
      .filter((p) => filterFields.length === 0 || filterFields.includes(p.product_bk));

    return prods.map((product) => {
      const value = `${ppt.category_bk}-${product.product_bk}`;
      return {
        product_bk: product.product_bk,
        category_bk: ppt.category_bk,
        value: value as definitions['Item Product BK Options'],
        label: product.productAdminName,
      };
    });
  });
};

export const getMinSpendProductTypeOptions = (
  promoProductTypes: PromoProductTypes,
  filterFields = [],
): Array<ProductTypeOption> => {
  return promoProductTypes
    .filter((category) => category.canUseForProductLevelMinSpend)
    .flatMap((ppt) => {
      const prods = ppt.products
        .filter((p) => p.canUseForProductLevelMinSpend)
        .filter((p) => filterFields.length === 0 || filterFields.includes(p.product_bk));

      return prods.map((product) => {
        const value = `${ppt.category_bk}-${product.product_bk}`;
        return {
          product_bk: product.product_bk,
          category_bk: ppt.category_bk,
          value: value as definitions['Item Product BK Options'],
          label: product.productAdminName,
        };
      });
    });
};

export function getPromoProductDisplayName(
  promoProductTypes: PromoProductTypes,
  categoryBK: string,
  subCategoryBK: string,
): string {
  if (!promoProductTypes) {
    return `${categoryBK}-${subCategoryBK}....`;
  }

  const productMatch = promoProductTypes
    .flatMap((ppt) => ppt.products)
    .find((p) => {
      return subCategoryBK === `${categoryBK}-${p.product_bk}`;
    });
  if (productMatch) {
    return productMatch.productAdminName;
  }

  const categoryMatch = promoProductTypes.find((ppt) => ppt.category_bk === categoryBK);
  if (categoryMatch) {
    return categoryMatch.categoryName;
  }

  return `${categoryBK}-${subCategoryBK}`;
}

export async function addPromo(object) {
  return request(basePath() + 'promo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export type CreatePromoUserPayload =
  operations['createOrUpdatePromoCodeUsers']['parameters']['body']['payload']['promoUsers'];

export async function createOrUpdatePromoUsers(
  payload: operations['createOrUpdatePromoCodeUsers']['parameters']['body']['payload'],
) {
  if (payload.promoUsers.length !== 1 && payload.promoUsers[0].id_promo_code_user) {
    throw new Error('Only one promo user can be updated at a time');
  }

  const url = `${basePath()}${
    payload.promoUsers[0].id_promo_code_user ? `promo/users/${payload.promoUsers[0].id_promo_code_user}` : 'promo/users'
  }`;
  return request(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export async function createOrUpdatePromoCondition(
  payload: operations['createOrUpdatePromoCodeCondition']['parameters']['body']['payload'],
) {
  const url = `${basePath()}${
    payload.promoCodeCondition.id_promo_code_condition
      ? `promo/condition/${payload.promoCodeCondition.id_promo_code_condition}`
      : 'promo/condition'
  }`;
  return request(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export function deletePromoCodeCondition(id: string) {
  return request(`${basePath()}promo/condition/${id}`, {
    method: 'DELETE',
  });
}

export function deletePromoUser(id: string) {
  return request(`${basePath()}promo/users/${id}`, {
    method: 'DELETE',
  });
}

export type GetPromoCodeProps = {
  page: number;
  codeName: string;
  emailAddress: string;
  brand: string;
  limit: number;
  caseId: string;
  expiresAfter: string;
  noChildPromos: boolean;
  excludeReferralPromos?: boolean;
  userIdInteracted?: string;
  deptTag: string;
  categoryTag: string;
  subCategoryTag: string;
  isDev: boolean;
};

export async function getPromoCodes(props: GetPromoCodeProps) {
  const query: operations['searchPromos']['parameters']['query'] = {
    page: props.page?.toString(),
    limit: props.limit?.toString(),
    brand: props.brand as operations['searchPromos']['parameters']['query']['brand'],
    code_name: props.codeName,
  };

  if (props.page == undefined) {
    query.page = '1';
  }
  if (!props.limit) {
    query.limit = '10';
  }
  if (!props.brand) {
    query.brand = 'luxuryescapes';
  }
  if (props.userIdInteracted?.length > 0) {
    query.user_id_interacted = props.userIdInteracted;
  }
  if (props.caseId?.length > 0) {
    query.case_id = props.caseId;
  }

  if (props.emailAddress?.length > 0) {
    query.email_address = props.emailAddress;
  }
  if (props.expiresAfter?.length > 0) {
    query.expires_after = props.expiresAfter;
  }

  if (props.noChildPromos) {
    query.exclude_child_promos = props.noChildPromos ? 'true' : 'false';
  }
  if (props.excludeReferralPromos) {
    query.exclude_referral_promos = props.excludeReferralPromos ? 'true' : 'false';
  }
  if (props.deptTag) {
    query.dept_tag = props.deptTag;
  }

  if (props.categoryTag) {
    query.category_tag = props.categoryTag;
  }
  if (props.subCategoryTag) {
    query.sub_category_tag = props.subCategoryTag;
  }
  const queryString = qs.stringify(query);
  return request(`${basePath()}promo/v2/codes?${queryString}`, { method: 'GET' });
}

/**
 * @deprecated This legacy endpoint does not support orders with multiple items, or promos with multiple discounts. The log endpoint will always apply new server-side validation and order placed with promos via this endpoint can cause "Promo amount does not match the promo definition" errors. Use with extreme caution and consider the /api/promo/discount endpoint instead.
 */
export async function getPromo(object) {
  const queryParams = {
    brand: object.brand,
    user_id: object.user_id,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/codes/${object.code_name}?${queryString}`, {
    method: 'GET',
  });
}

interface GetInternalPromoProps {
  code_name: string;
  brand: string;
}

export async function getInternalPromo({ brand, code_name }: GetInternalPromoProps) {
  const queryParams = {
    brand: brand,
  };
  const encodedCodeName = encodeURIComponent(code_name);
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/code/${encodedCodeName}?${queryString}`, {
    method: 'GET',
  });
}

type getPromoChildrenProps = {
  id_promo_code: string;
  page: number;
  limit: number;
};

export async function getPromoClones({ id_promo_code, page, limit }: getPromoChildrenProps) {
  const queryParams = {
    page: page,
    limit: limit,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/id/${id_promo_code}/clones?${queryString}`, {
    method: 'GET',
    accept: 'application/json',
  });
}

type BulkCreatePromosProps = {
  promo_prefix: string;
  number_to_create: number;
  expires_at: string;
  id_promo_code: string;
};

export async function bulkCreatePromos({
  id_promo_code,
  number_to_create,
  expires_at,
  promo_prefix,
}: BulkCreatePromosProps) {
  return request(`${basePath()}promo/id/${id_promo_code}/bulk`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      promo_prefix,
      number_to_create,
      expires_at,
    }),
  });
}

export async function getReferralPromo({ codeName, regionCode, brand, customerId, cartValue }) {
  return request(`${basePath()}referral/promo`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code: codeName,
      country_code: regionCode,
      brand,
      id_account: customerId,
      cart_value: cartValue,
    }),
  });
}

export async function getPromoById({ idPromo, brand = undefined }) {
  return request(`${basePath()}promo/id/${idPromo}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

type getLogsByPromoProps = {
  codeName: string;
  brand: string;
  userId: string;
  includeDeleted?: boolean;
  page?: number;
  limit?: number;
};

export async function getLogsByPromo({
  codeName,
  brand,
  userId,
  includeDeleted = false,
  page = 0,
  limit = 10,
}: getLogsByPromoProps) {
  const params = {
    brand,
    userId,
    includeDeleted,
    page,
    limit,
  };
  return request(`${basePath()}promo/log/${codeName}?${qs.stringify(params)}`, {
    method: 'GET',
  });
}

export async function debugPromoByOrderID(
  codeName: string,
  orderId: string,
  brand: string,
  useOrignalUserAccount: boolean,
  ignoreLuxPlusPricing: boolean,
  errorBodyCallback: (body: any) => void,
) {
  const params = qs.stringify({
    brand,
    useOrignalUserAccount,
    ignoreLuxPlusPricing,
  });

  return request(`${basePath()}promo/discount/${codeName}/orderId/${orderId}?${params}`, {
    method: 'GET',
    errorBodyCallback: errorBodyCallback,
  });
}

export type PromoRequestFilters = {
  codeName: string;
  userId: string;
  brand: definitions['Promo Brands'];
  errorCode: string;
  page: number;
  limit: number;
};

type PromoRequestRes = {
  status: number;
  message?: string;
  result: {
    promoRequests: Array<PromoRequest>;
  };
  total: number;
};

export function getPromoCodeRequestsParams(
  filters: PromoRequestFilters,
): operations['getPromoRequests']['parameters']['query'] {
  const config: operations['getPromoRequests']['parameters']['query'] = {
    page: filters.page.toString(),
    limit: filters.limit.toString(),
  };

  if (filters.userId) {
    config.userId = filters.userId;
  }
  if (filters.codeName) {
    config.codeName = filters.codeName;
  }

  if (filters.errorCode) {
    config.errorCode = filters.errorCode as operations['getPromoRequests']['parameters']['query']['errorCode'];
  }

  if (filters.brand) {
    config.brand = filters.brand;
  }
  return config;
}

export type PromoFailure =
  operations['getPromoLogFailures']['responses']['200']['content']['application/json']['result']['promoFailures'];

export type PromoFailureRes = {
  status: number;
  message?: string;
  count: number;
  result: {
    promoFailures: Array<PromoFailure>;
    promoFailureMetrics: operations['getPromoLogFailures']['responses']['200']['content']['application/json']['result']['promoFailureMetrics'];
  };
  total: number;
};

export type PromoFailureFilters = operations['getPromoLogFailures']['parameters']['query'];

export async function getPromoCodeFailures(qp: PromoFailureFilters): Promise<PromoFailureRes> {
  const queryParams = qs.stringify(getPromoCodeFailuresParams(qp));
  return request(`${basePath()}promo/failures?${queryParams}`, {
    method: 'GET',
  });
}

export type ProcessReferralLogFilters = operations['processReferralLogs']['parameters']['body']['payload'];

export async function processReferralLogs(payload: ProcessReferralLogFilters) {
  return await request(`${basePath()}referral/logs`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export function getPromoCodeFailuresParams(
  filters: PromoFailureFilters,
): operations['getPromoLogFailures']['parameters']['query'] {
  const qs: operations['getPromoLogFailures']['parameters']['query'] = {
    page: filters.page,
    limit: filters.limit,
    brand: filters.brand,
  };

  if (filters.user_id) {
    qs.user_id = filters.user_id;
  }
  if (filters.code_name) {
    qs.code_name = filters.code_name;
  }

  if (filters.load_metrics) {
    qs.load_metrics = filters.load_metrics;
  }

  if (filters.error_level) {
    qs.error_level = filters.error_level;
  }

  if (filters.brand) {
    qs.brand = filters.brand;
  }

  if (filters.region) {
    qs.region = filters.region;
  }
  return qs;
}

export async function getPromoCodeRequests(qp: PromoRequestFilters): Promise<PromoRequestRes> {
  const queryParams = qs.stringify(getPromoCodeRequestsParams(qp));
  return request(`${basePath()}promo/requests?${queryParams}`, {
    method: 'GET',
  });
}

export async function updatePromo(id, brand, promoData) {
  return request(`${basePath()}promo/id/${id}?brand=${brand}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(promoData),
  });
}

export async function expirePromo(id, brand) {
  return request(`${basePath()}promo/id/${id}?${qs.stringify({ brand })}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      expires_at: dateNow(),
    }),
  });
}

export async function getGiftCardLogs(giftCardCode, brand) {
  return request(`${basePath()}gift-card/log/${giftCardCode}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

export async function searchGiftCardLogs({
  code,
  addedBy,
  redeemedBy,
  pageNum,
  pageSize,
  brand,
}: {
  code?: string;
  addedBy?: string;
  redeemedBy?: string;
  pageNum?: number;
  pageSize?: number;
  brand: string;
}): Promise<operations['searchGiftCardLogs']['responses']['200']['content']['application/json']> {
  return request(
    `${basePath()}gift-card/search-log?${qs.stringify(
      { code, addedBy, redeemedBy, pageNum, pageSize, brand },
      { skipNulls: true },
    )}`,
    {
      method: 'GET',
    },
  );
}

export async function searchBlackhawkGiftCardLogs({
  code,
  userId,
  pageNum,
  pageSize,
  brand,
}: {
  code?: string;
  userId?: string;
  pageNum?: number;
  pageSize?: number;
  brand: string;
}): Promise<operations['searchBlackhawkGiftCardLogs']['responses']['200']['content']['application/json']> {
  return request(
    `${basePath()}gift-card/blackhawk/log?${qs.stringify(
      { code, userId, pageNum, pageSize, brand },
      { skipNulls: true },
    )}`,
    {
      method: 'GET',
    },
  );
}

export async function getGiftCards({
  code,
  addedBy,
  redeemedBy,
  pageNum = 0,
  pageSize = 20,
  brand,
  email,
}: {
  code?: string;
  addedBy?: string;
  redeemedBy?: string;
  email?: string;
  pageNum: number;
  pageSize: number;
  brand: string;
}): Promise<operations['getGiftCards']['responses']['200']['content']['application/json']> {
  return request(
    `${basePath()}gift-card?${qs.stringify(
      { code, addedBy, redeemedBy, pageNum, pageSize, brand, email },
      { skipNulls: true },
    )}`,
    {
      method: 'GET',
    },
  );
}

export async function redeemGiftCard(code, customerId, brand) {
  return request(`${basePath()}gift-card/code/${code}/redeem?${qs.stringify({ brand })}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      customerIdFromAdmin: customerId,
      gift_card_code: code,
    }),
  });
}

export async function updateGiftCardStatus({
  code,
  status,
}: {
  code: string;
  status: operations['/api/gift-card/code/{code}/patch']['parameters']['body']['payload']['gift_card_status'];
}): Promise<operations['/api/gift-card/code/{code}/patch']['responses']['200']['content']['application/json']> {
  return request(`${basePath()}gift-card/code/${code}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      gift_card_status: status,
      gift_card_code: code,
    }),
  });
}

export async function listInAppPromoMessage(brand, region, status, startAfter, endBefore) {
  return request(
    `${basePath()}promo/feature-schedule/list?${qs.stringify({
      brand,
      region,
      active: status,
      startAfter: formatDateDateTimeWidget(startAfter),
      endBefore: formatDateDateTimeWidget(endBefore),
    })}`,
    {
      method: 'GET',
    },
  );
}

export async function getSchemaForInAppMessagePromo() {
  return request(`${basePath()}promo/feature-schedule`, {
    method: 'OPTIONS',
  });
}

export async function createOrUpdateInAppMessagePromo(
  id,
  codeName,
  region,
  status,
  brand,
  startAt,
  endAt,
  heading,
  details,
) {
  return request(`${basePath()}promo/feature-schedule`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      featuredPromoSchedule: {
        id,
        codeName,
        region,
        active: status,
        brand,
        startAt: formatDateDateTimeWidget(startAt),
        endAt: formatDateDateTimeWidget(endAt),
        heading,
        details,
      },
    }),
  });
}

export async function deleteInAppMessagePromoById(id) {
  return request(`${basePath()}promo/feature-schedule/${id}`, {
    method: 'DELETE',
    ignoreResponse: true,
  });
}

export async function addCompedPackage(object) {
  return request(basePath() + 'promo/comped-package', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getCompedPackages(brand = 'luxuryescapes', page = 1, limit = 10) {
  const params = {
    brand,
    page,
    limit,
  };

  return request(`${basePath()}promo/comped-package?${qs.stringify(params)}`, {
    method: 'GET',
  });
}

export async function getCompedPackageById(id, brand = 'luxuryescapes') {
  return request(`${basePath()}promo/comped-package/${id}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

export async function updateCompedPackage(id, compedData, brand = 'luxuryescapes') {
  return request(`${basePath()}promo/comped-package/${id}?brand=${brand}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(compedData),
  });
}

export async function getBundleByOfferIdRegionAndBrand(offer_id, region, brand) {
  return request(
    `${basePath()}promo/bundle?${qs.stringify({
      offer_id,
      region,
      brand,
    })}`,
    {
      method: 'GET',
    },
  );
}

export async function createOrUpdateBundle(object) {
  return request(`${basePath()}promo/bundle`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function deleteBundleById(object) {
  return request(`${basePath()}promo/bundle/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getPromoUsers(props: GetPromoUserFilters) {
  const queryParameters = {
    page: props.page,
    limit: props.limit,
    code_name: props.code_name ? props.code_name : null,
    user_id: props.user_id ? props.user_id : null,
    id_promo_code: props.id_promo_code ? props.id_promo_code : null,
    brand: props.brand,
    region: props.region,
    expires_after: props.expires_after,
  };

  return request(`${basePath()}promo/users?${qs.stringify(queryParameters, { skipNulls: true })}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function createOrUpdatePromoDiscount(
  discountObject,
): Promise<operations['createOrUpdatePromoDiscount']['responses']['200']['content']['application/json']> {
  return request(
    `${basePath()}promo/id/${discountObject.id_promo_code}/discount/${discountObject.id_promo_code_discount}`,
    {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ promoDiscount: discountObject }),
    },
  );
}

export function deletePromoDiscount(promoId: string, id: string) {
  return request(`${basePath()}promo/id/${promoId}/discount/${id}`, {
    method: 'DELETE',
  });
}

type ReferralLogResponse = {
  config: ReferralConfig;
  result: {
    logs: Array<unknown>;
    total: number;
  };
};

export async function getReferralLogs(
  brand,
  userId,
  logStatus,
  createdAfter,
  orderId,
  pageNum,
  pageSize,
): Promise<ReferralLogResponse> {
  const queryParameters: operations['getReferralLogs']['parameters']['path'] = {
    ...(userId && { user_id: userId }),
    ...(logStatus && logStatus !== 'all' && { referral_log_status: logStatus }),
    ...(createdAfter && { created_before: createdAfter }),
    ...(orderId && { order_id: orderId }),
    page_num: pageNum ?? 1,
    page_size: pageSize ?? 20,
  };

  return request(`${basePath()}referral/logs/debug?${qs.stringify(queryParameters)}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

type ReferralLogResetResponse = operations['resetReferralLog']['responses']['200']['content']['application/json'];

export async function resetPromoLog(referralLogId): Promise<ReferralLogResetResponse> {
  return request(`${basePath()}referral/logs/${referralLogId}/reset`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function getPromoDiscountSchema() {
  return request(
    `${basePath()}promo/id/241bdbd6-0a4c-4470-8495-4ded391a3728/discount/241bdbd6-0a4c-4470-8495-4ded391a3728`,
    {
      method: 'OPTIONS',
    },
  );
}

export async function quickAddPromo(object) {
  return request(basePath() + 'promo/quickCreate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function quickAddPromoOptions() {
  return request(basePath() + 'promo/quickCreate', {
    method: 'OPTIONS',
  });
}

export async function getPromoMeta(): Promise<
  operations['getPromoMeta']['responses']['200']['content']['application/json']
> {
  return request(`${basePath()}promo/meta`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
}

type getChannelMarkupByListResponse =
  operations['getChannelMarkupList']['responses']['200']['content']['application/json'];
export async function getChannelMarkupList({
  pageNum,
  pageSize,
  brand,
  status,
}: {
  pageNum: number;
  pageSize: number;
  brand: string;
  status: 'active' | 'expired' | 'all';
}): Promise<getChannelMarkupByListResponse> {
  const queryParams = {
    pageSize,
    page: pageNum,
    brand,
    status,
  };
  const queryString = qs.stringify(queryParams);

  return request(`${basePath()}promo/channel-markup/list?${queryString}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

type getChannelMarkupByIdResponse =
  operations['getChannelMarkupById']['responses']['200']['content']['application/json'];
export async function getChannelMarkupById(channelMarkupId: string): Promise<getChannelMarkupByIdResponse> {
  return request(`${basePath()}promo/channel-markup/${channelMarkupId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

type expireChannelMarkupResponse = operations['expireChannelMarkup']['responses']['200']['content']['application/json'];
export async function expireChannelMarkup(channelMarkupId: string): Promise<expireChannelMarkupResponse> {
  return request(`${basePath()}promo/channel-markup/${channelMarkupId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

type createChannelMarkupResponse = operations['createChannelMarkup']['responses']['201']['content']['application/json'];
export async function createChannelMarkup(data: {
  source: string;
  medium: string;
  campaign: string;
  markup: number;
  brand: string;
}): Promise<createChannelMarkupResponse> {
  return request(`${basePath()}promo/channel-markup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export type GetContentfulFormsResponse =
  operations['getContentfulForms']['responses']['200']['content']['application/json'];
export async function getContentfulForms({
  pageNum,
  pageSize,
  brand,
  slugKeywords,
}: {
  pageNum: number;
  pageSize: number;
  brand: string;
  slugKeywords: string;
}): Promise<GetContentfulFormsResponse> {
  const queryParams = {
    pageSize,
    pageNum,
    slugKeywords,
    brand,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/contentful-forms?${queryString}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export function downloadContentfulFormCsv(slug: string) {
  const downloadUrl = `${basePath()}promo/contentful-forms/${slug}.csv`;
  return request(downloadUrl, {
    download: true,
    method: 'GET',
  });
}

type GetPromoDisplayConfigResponse =
  operations['getPromoDisplayConfigs']['responses']['200']['content']['application/json'];
type GetPromoDisplayConfigParams = operations['getPromoDisplayConfigs']['parameters']['query'];
export async function getPromoDisplayConfigs(
  params: GetPromoDisplayConfigParams,
): Promise<GetPromoDisplayConfigResponse> {
  const nonEmptyParams = filterOutEmptyFields(params);
  // Adding a random tick to the query string to prevent caching
  const queryString = qs.stringify({ ...nonEmptyParams, tick: Date.now() });

  return request(`${basePath()}promo/promo-display-config?${queryString}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

type createPromoDisplayConfigPayload = operations['createPromoDisplayConfig']['parameters']['body']['payload'];
type createPromoDisplayConfigResponse =
  operations['createPromoDisplayConfig']['responses']['201']['content']['application/json'];
export async function createPromoDisplayConfig(
  data: createPromoDisplayConfigPayload,
): Promise<createPromoDisplayConfigResponse> {
  return request(`${basePath()}promo/promo-display-config`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filterOutEmptyFields(data)),
  });
}

type expirePromoDisplayConfigResponse =
  operations['expirePromoDisplayConfig']['responses']['200']['content']['application/json'];
export async function expirePromoDisplayConfig(id: string): Promise<expirePromoDisplayConfigResponse> {
  return request(`${basePath()}promo/promo-display-config/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

type updatePromoDisplayConfigPayload = operations['updatePromoDisplayConfig']['parameters']['body']['payload'];
type updatePromoDisplayConfigResponse =
  operations['updatePromoDisplayConfig']['responses']['200']['content']['application/json'];
export async function updatePromoDisplayConfig(
  data: updatePromoDisplayConfigPayload,
): Promise<updatePromoDisplayConfigResponse> {
  return request(`${basePath()}promo/promo-display-config`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filterOutEmptyFields(data)),
  });
}

type getReferralEarnOptionQueryParams = operations['getReferralEarnOptionList']['parameters']['query'];
export type getReferalEarnOptionResponse =
  operations['getReferralEarnOptionList']['responses']['200']['content']['application/json'];
export async function getReferralEarnOptions(
  payload: getReferralEarnOptionQueryParams,
): Promise<getReferalEarnOptionResponse> {
  const queryString = qs.stringify(payload);
  return request(`${basePath()}referral/earn-options/list?${queryString}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export type createReferralEarnOptionPayload = operations['createReferralEarnConfig']['parameters']['body']['payload'];
type createReferralEarnOptionResponse =
  operations['createReferralEarnConfig']['responses']['201']['content']['application/json'];
export async function createReferralEarnOption(
  payload: createReferralEarnOptionPayload,
): Promise<createReferralEarnOptionResponse> {
  return request(`${basePath()}referral/earn-options`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export async function expireReferralEarnOption(id: string) {
  return request(`${basePath()}referral/earn-options/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

const defaultExpectedFields = {
  productType: true,
  discountableTotal: true,
  offerId: true,
  itemId: true,
  categoryBK: true,
  subCategoryBK: true,
  provider: true,
  luxPlusPrice: false,
  numberOfNights: false,
  numberOfAdults: false,
  numberOfChildren: false,
  originAirportCode: false,
  itemCountryCode: false,
  travellers: false,
  itemInfoString: false,
  reservationType: false,
  isMemberPrice: false,
};

export const productTypesExpectedFields: Record<
  definitions['Discount Request']['order']['items']['0']['categoryBK'],
  Record<keyof definitions['Discount Request']['order']['items']['0'], boolean>
> = {
  ALL: defaultExpectedFields,
  flight: {
    ...defaultExpectedFields,
    originAirportCode: true,
    itemCountryCode: true,
    provider: true,
  },
  transfer: {
    ...defaultExpectedFields,
    subCategoryBK: false,
  },
  addon: defaultExpectedFields,
  subscription: {
    ...defaultExpectedFields,
    provider: false,
  },
  car_hire: defaultExpectedFields,
  experience: defaultExpectedFields,
  tour: {
    ...defaultExpectedFields,
    provider: true,
    itemCountryCode: true,
    numberOfAdults: true,
    numberOfChildren: true,
    reservationType: false,
  },
  hotel: {
    ...defaultExpectedFields,
    reservationType: true,
    isMemberPrice: true,
    discountableTotal: true,
    travellers: true,
    itemCountryCode: true,
  },
  cruise: {
    ...defaultExpectedFields,
    itemCountryCode: true,
  },
  service_fee: {
    ...defaultExpectedFields,
    travellers: false,
    itemCountryCode: false,
    subCategoryBK: false,
    reservationType: false,
  },
  insurance: {
    ...defaultExpectedFields,
    itemCountryCode: false,
    provider: false,
  },
};
