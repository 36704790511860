import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getRegionCodes } from '@luxuryescapes/lib-regions';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';
import useToggleState from '~/hooks/useToggleState';

import {
  expireReferralEarnOption,
  getReferalEarnOptionResponse,
  getReferralEarnOptions,
} from '~/services/PromoService';

import { filterOutEmptyFields } from '~/utils/objectUtils';

import PageSubheader from '../Common/Elements/PageSubheader';

import ReferralEarnOptionForm from './ReferralEarnOptionForm';
import { EarnOptionBrand, EarnOptionRegion, EarnOptionStatus, ReferralEarnStep } from './utils';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 0.5,
};

const getColumns = ({
  expireReferralEarnOption,
}: {
  expireReferralEarnOption: (id: string) => Promise<void>;
}): Array<GridColDef> => [
  {
    field: 'earn_option_id',
    headerName: 'ID',
    ...sharedOpts,
    flex: 0.2,
  },
  {
    field: 'earn_type',
    headerName: 'Earn Type',
    ...sharedOpts,
    flex: 0.3,
    renderCell: (params) => {
      const hasValue = !(params.row.earn_type.earn_type_code === 'promo_code');
      return (
        <Stack>
          <Typography>
            <b>Earn Type:</b> {params.row.earn_type.earn_type_code}
          </Typography>
          <Typography>
            <b>Earn Step:</b> {params.row.earn_step}
          </Typography>
          <Typography>
            <b>Earn Priority:</b> {params.row.earn_priority}
          </Typography>
          <Typography>(higher number means lower priority)</Typography>
          {hasValue && (
            <Typography>
              <b>Earn Value:</b> {params.row.earn_type.earn_model.value}
            </Typography>
          )}
          {!hasValue && (
            <Typography>
              <b>Promo Code:</b> {params.row.earn_type.code_name}
            </Typography>
          )}
        </Stack>
      );
    },
  },
  {
    field: 'Details',
    headerName: 'Details',
    ...sharedOpts,
    renderCell: (params) => {
      return (
        <Stack>
          <Typography>
            <b>Name:</b> {params.row.name}
          </Typography>
          <Typography>
            <b>Display Text:</b> {params.row.display_name}
          </Typography>
          <Typography>
            <b>Region:</b> {params.row.region}
          </Typography>
          <Typography>
            <b>Summary</b> {params.row.summary}
          </Typography>
          <Typography>
            <b>Comment:</b> {params.row.comment}
          </Typography>
          <Typography>
            <b>Description:</b> {params.row.long_description}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'Date Time',
    headerName: 'Date Time',
    type: 'dateTime',
    ...sharedOpts,
    renderCell: (params) => {
      return (
        <Stack>
          <Typography>
            <b>Created At:</b> {params.row.created_at}
          </Typography>
          <Typography>
            <b>Expired At:</b> {params.row.expired_at}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'Actions',
    headerName: 'Actions',
    ...sharedOpts,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={params.row.expired_at}
            onClick={() => expireReferralEarnOption(params.row.earn_option_id)}
          >
            Expire
          </Button>
        </Stack>
      );
    },
  },
];

function ReferralEarnOptionsContainer() {
  const query = useQuery();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useCurrentTenant();
  const [data, setData] = useState<getReferalEarnOptionResponse['result']['earn_options']>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNum, setPageNum] = useState<number>(query.get('page_num') ? Number(query.get('page_num')) : 0);
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : 10);
  const [status, setStatus] = useState<EarnOptionStatus | 'all'>(
    ((query.get('status') as EarnOptionStatus) ?? 'all') || undefined,
  );
  const [region, setRegion] = useState<EarnOptionRegion>((query.get('region') as EarnOptionRegion) || undefined);
  const [earnStep, setEarnStep] = useState<ReferralEarnStep>((query.get('earn_step') as ReferralEarnStep) || undefined);
  const {
    isToggled: isEarnOptionFormOpen,
    toggleOn: setOpenEarnOptionForm,
    toggleOff: setCloseEarnOptionForm,
  } = useToggleState(false);

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify(
      filterOutEmptyFields({
        page_num: pageNum.toString(),
        page_size: pageSize.toString(),
        status: status,
        region,
      }),
    );
    history.push({
      search: `?${searchParams}`,
    });
  }, [pageNum, pageSize, status, region, history]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        result: { earn_options, total },
      } = await getReferralEarnOptions(
        filterOutEmptyFields({
          brand: tenant.brand as EarnOptionBrand,
          region,
          status,
          page: pageNum,
          pageSize,
          earn_step: earnStep,
        }),
      );
      setData(earn_options ?? []);
      setTotalCount(Number(total));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Referral Earn Options fetch failed', { variant: 'error' });
    }
    setIsLoading(false);
  }, [tenant.brand, region, status, pageNum, pageSize, earnStep, setQueryParams, enqueueSnackbar]);

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page);
      setPageSize(pageSize);
      query.set('page_num', page.toString());
      query.set('page_size', pageSize.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
      fetchData();
    },
    [fetchData, query],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, region, status]);

  const handleExpireReferralEarnOption = useCallback(
    async (id: string) => {
      try {
        await expireReferralEarnOption(id);
        enqueueSnackbar('Expired the referral earn option successfully', { variant: 'success' });
        fetchData();
      } catch (error) {
        enqueueSnackbar(`Failed to expired the referral earn option: ${error}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, fetchData],
  );

  return (
    <>
      <Helmet>
        <title>Promo Display Config</title>
      </Helmet>
      <PageSubheader title="Referral Earn Options" />
      <Grid container spacing={2}>
        <Grid xs={8} container spacing={1}>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Autocomplete
                id="region"
                value={region}
                options={getRegionCodes()}
                onChange={(_, value) => setRegion(value as EarnOptionRegion)}
                renderInput={(params) => <TextField {...params} label="Region" />}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="status"
              value={status}
              options={['all', 'active', 'inactive']}
              onChange={(_, value) => setStatus((value as EarnOptionStatus) ?? 'all')}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Autocomplete
                id="region"
                options={['signup', 'booking', 'post_travel']}
                onChange={(_, value) => setEarnStep(value as ReferralEarnStep)}
                renderInput={(params) => <TextField {...params} label="Earn Step" />}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={4} container>
          <Stack spacing={2} direction="row">
            <Button type="button" variant="contained" color="primary" onClick={setOpenEarnOptionForm}>
              Create New Referral Earn Option
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {isEarnOptionFormOpen && <ReferralEarnOptionForm closeModal={setCloseEarnOptionForm} fetchData={fetchData} />}
      <Box mt={2}>
        <DataGrid
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          rows={data.map((item, index) => ({ ...item, id: index }))}
          columns={getColumns({ expireReferralEarnOption: handleExpireReferralEarnOption })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
          rowHeight={200}
        />
      </Box>
    </>
  );
}

export default ReferralEarnOptionsContainer;
