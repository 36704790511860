import { operations } from '@luxuryescapes/contract-svc-promo';
import { components } from '@luxuryescapes/contract-svc-promo/generated';

export type EarnOptionTypeCode =
  operations['createReferralEarnConfig']['parameters']['body']['payload']['earn_type']['earn_type_code'];
export type EarnOptionType = operations['createReferralEarnConfig']['parameters']['body']['payload']['earn_type'];
export type ReferralEarnStep = operations['createReferralEarnConfig']['parameters']['body']['payload']['earn_step'];
export type EarnOptionRegion = operations['getReferralEarnOptionList']['parameters']['query']['region'];
export type EarnOptionBrand = operations['getReferralEarnOptionList']['parameters']['query']['brand'];
export type EarnOptionStatus = operations['getReferralEarnOptionList']['parameters']['query']['status'];

export const earnTypeIsPromoCode = (
  earnType: EarnOptionType,
): earnType is components['schemas']['PromoServiceEarnOptionTypeWithPromoCodeName'] => {
  return earnType.earn_type_code === 'promo_code';
};

export const earnTypeIsCredit = (
  earnType: EarnOptionType,
): earnType is components['schemas']['PaymentServiceEarnOptionType'] => {
  return earnType.earn_type_code === 'credit';
};
