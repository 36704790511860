import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';
import { getRegionCodes } from '@luxuryescapes/lib-regions';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { createPromoDisplayConfig, updatePromoDisplayConfig } from '~/services/PromoService';

type AuthState = 'logged_in' | 'logged_out' | 'logged_in_and_out' | null;
type DisplayMode = 'appear_once' | 'persistence' | null;

const isValidQueryParamsRegex = /^[^\s]+$/;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchData: () => void;
  editableConfig?: operations['updatePromoDisplayConfig']['parameters']['body']['payload'];
}

function PromoDisplayConfigForm({ open, setOpen, fetchData, editableConfig }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [region, setRegion] = useState<string | null>(editableConfig?.region);
  const [codeName, setCodeName] = useState<string | null>(editableConfig?.code_name);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(editableConfig?.display_mode ?? 'appear_once');
  const [authState, setAuthState] = useState<AuthState>(editableConfig?.auth_state ?? 'logged_in_and_out');
  const [isTargetedPromo, setIsTargetedPromo] = useState<boolean>(editableConfig?.is_targeted_promo ?? false);
  const isEditing = !!editableConfig;
  const tenant = useCurrentTenant();
  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCreate = useCallback(
    async (data) => {
      const response = await createPromoDisplayConfig({
        ...data,
        brand: tenant.tenant.brand,
        region,
        display_mode: displayMode,
        auth_state: authState,
        delay_duration: Number(data.delay_duration),
        code_name: codeName,
        is_targeted_promo: isTargetedPromo,
        targeted_promo_audience_length: isTargetedPromo ? Number(data.targeted_promo_audience_length) : undefined,
      });

      if (response.status !== 201) {
        enqueueSnackbar('Promo Display Config creation failed', { variant: 'error' });
        return;
      }

      reset();
      fetchData();
      handleClose();
      enqueueSnackbar('Promo Display Config created successfully', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData, reset, tenant.tenant.brand, region, displayMode, authState, isTargetedPromo],
  );

  const handleEdit = useCallback(
    async (data) => {
      const response = await updatePromoDisplayConfig({
        ...data,
        brand: tenant.tenant.brand,
        promo_display_config_id: editableConfig.promo_display_config_id,
        region,
        display_mode: displayMode,
        auth_state: authState,
        delay_duration: Number(data.delay_duration),
        code_name: codeName,
        is_targeted_promo: isTargetedPromo,
        targeted_promo_audience_length: isTargetedPromo ? Number(data.targeted_promo_audience_length) : undefined,
      });

      if (response.status !== 200) {
        enqueueSnackbar('Promo Display Config edit failed', { variant: 'error' });
        return;
      }

      reset();
      fetchData();
      handleClose();
      enqueueSnackbar('Promo Display Config edited successfully', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData, reset, tenant.tenant.brand, region, displayMode, authState, isTargetedPromo],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={handleClose}
      component="form"
      onSubmit={handleSubmit(isEditing ? handleEdit : handleCreate)}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="actual-title">
        {isEditing ? 'Edit' : 'Create'} a Promo Display Config
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <TextField
                  id="code_name"
                  label="code_name"
                  variant="outlined"
                  value={codeName}
                  error={!!errors.code_name}
                  helperText={errors.code_name?.message}
                  autoComplete="off"
                  disabled={isEditing}
                  onChange={(e) => setCodeName(e.target.value)}
                />
                <TextField
                  id="url_path"
                  label="URL path"
                  variant="outlined"
                  {...register('url_path', {
                    required: 'URL path is required',
                  })}
                  defaultValue={editableConfig?.url_path}
                  error={!!errors.url_path}
                  helperText={errors.url_path?.message}
                  autoComplete="off"
                />
                <Autocomplete
                  id="region"
                  options={getRegionCodes()}
                  value={region}
                  onChange={(_, newValue) => {
                    setRegion(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Region" />}
                />
                <Autocomplete<DisplayMode>
                  id="display_mode"
                  options={['appear_once', 'persistence']}
                  value={displayMode}
                  onChange={(_, newValue) => {
                    setDisplayMode(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Display Mode" />}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_targeted_promo"
                      defaultChecked={isTargetedPromo}
                      onChange={(e) => setIsTargetedPromo(e.target.checked)}
                    />
                  }
                  label="Enable targeted promo"
                />
                <Tooltip title="Unit of delay duration is in seconds" placement="bottom">
                  <TextField
                    id="delay_duration"
                    label="Delay Duration"
                    variant="outlined"
                    {...register('delay_duration', {
                      required: 'Delay Duration is required',
                    })}
                    inputProps={{ min: 0 }}
                    type="number"
                    defaultValue={editableConfig?.delay_duration}
                    error={!!errors.delay_duration}
                    helperText={errors.delay_duration?.message}
                    autoComplete="off"
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <TextField
                  id="source"
                  label="Source"
                  variant="outlined"
                  {...register('source', {
                    pattern: {
                      value: isValidQueryParamsRegex,
                      message: 'utm_source',
                    },
                  })}
                  defaultValue={editableConfig?.source}
                  error={!!errors.source}
                  helperText={errors.source?.message}
                  autoComplete="off"
                />
                <TextField
                  id="medium"
                  label="Medium"
                  variant="outlined"
                  {...register('medium', {
                    pattern: {
                      value: isValidQueryParamsRegex,
                      message: 'utm_medium',
                    },
                  })}
                  defaultValue={editableConfig?.medium}
                  error={!!errors.medium}
                  helperText={errors.medium?.message}
                  autoComplete="off"
                />
                <TextField
                  id="campaign"
                  label="Campaign"
                  variant="outlined"
                  {...register('campaign', {
                    pattern: {
                      value: isValidQueryParamsRegex,
                      message: 'utm_campaign',
                    },
                  })}
                  defaultValue={editableConfig?.campaign}
                  error={!!errors.campaign}
                  helperText={errors.campaign?.message}
                  autoComplete="off"
                />
                <Autocomplete
                  id="auth_state"
                  options={['logged_in', 'logged_out', 'logged_in_and_out']}
                  value={authState}
                  onChange={(_, newValue) => {
                    setAuthState(newValue as AuthState);
                  }}
                  renderInput={(params) => <TextField {...params} label="Auth State" />}
                />
                <Tooltip title="Unit of targeted promo audience length is hours" placement="bottom">
                  <TextField
                    id="targeted_promo_audience_length"
                    label="Promo Audience Length"
                    variant="outlined"
                    inputProps={{ min: 0 }}
                    {...register('targeted_promo_audience_length')}
                    type="number"
                    disabled={!isTargetedPromo}
                    defaultValue={editableConfig?.targeted_promo_audience_length}
                    error={!!errors.targeted_promo_audience_length}
                    helperText={errors.targeted_promo_audience_length?.message}
                    autoComplete="off"
                  />
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <TextField
                  id="header_text"
                  label="Header Text"
                  variant="outlined"
                  {...register('header_text', {
                    required: 'Header text is required',
                  })}
                  inputProps={{ maxLength: 40 }}
                  defaultValue={editableConfig?.header_text}
                  error={!!errors.header_text}
                  helperText={errors.header_text?.message}
                  autoComplete="off"
                />
                <TextField
                  id="body_text"
                  label="Body Text"
                  variant="outlined"
                  {...register('body_text', {
                    required: 'Body text is required',
                  })}
                  inputProps={{ maxLength: 80 }}
                  defaultValue={editableConfig?.body_text}
                  error={!!errors.body_text}
                  helperText={errors.body_text?.message}
                  multiline
                  rows={4}
                  autoComplete="off"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="submit">
          {isEditing ? 'Edit' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PromoDisplayConfigForm;
